import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, keywords, title, image, og, slug, country }) {
  const { icon, site } = useStaticQuery(
    graphql`
      query {
        icon: file(absolutePath: { regex: "/icon.png/" }) {
          childImageSharp {
            fixed(width: 512, height: 512) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            social {
              twitter
              facebookApp
            }
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const ogTitle = title || site.siteMetadata.title
  const locale = `${lang}_${country}`
  const url = site.siteMetadata.siteUrl + (slug == null ? "/" : slug)
  const type = (slug == null ? "website" : "article")
  const twitterCard = (image == null ? "summary" : "summary_large_image")

return (
    <Helmet
      htmlAttributes={{
        lang, og,
      }}
      defaultTitle={`${site.siteMetadata.title}`}
      titleTemplate={`%s - ${site.siteMetadata.title}`}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: ogTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: type,
        },
        {
          property: `og:locale`,
          content: locale,
        },
        {
          property: `fb:app_id`,
          content: site.siteMetadata.social.facebookApp
        },
        {
          property: `twitter:card`,
          content: twitterCard,
        },
        {
          property: `twitter:site`,
          content: site.siteMetadata.social.twitter,
        },
        {
          property: `twitter:creator`,
          content: site.siteMetadata.social.twitter,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(
          image != null
            ? {
                property: `og:image`,
                content: site.siteMetadata.siteUrl + image.childImageSharp.fluid.src,
              }
            : {
                property: `og:image`,
                content: site.siteMetadata.siteUrl + icon.childImageSharp.fixed.src,
              }
        )
        .concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `ja`,
  country: `JP`,
  og: `http://ogp.me/ns#`,
  meta: [],
  keywords: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  og: PropTypes.string.isRequired,
  slug: PropTypes.string,
  country: PropTypes.string,
}

export default SEO
