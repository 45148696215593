import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
              margin: `${rhythm(1)} 0 ${rhythm(1)}`,
              padding: `${rhythm(1 / 2)} 0 ${rhythm(1 / 2)}`,
              color: `#aaaaaa`,
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginTop: rhythm(1),
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 80,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <div>
              <p>
                このブログはITエンジニアの
                <a href={`https://twitter.com/${social.twitter}`} style={{color: `#b2791e`,}}>
                  {author}
                </a>
                が書いています。<br />
                <span style={{textDecorationLine: `line-through`}}>
                  しばらく無職やってましたがそろそろ仕事する気になってきましたんでどこか雇ってください。
                  Web開発がやりたいです。勉強したぶんRailsがいいんですけどこの際あまり贅沢は言いません。
                  ポートフォリオはもうちょっと待って（←これをもう数ヶ月は言ってる）。
                </span><br />
                <span>ちゃんとRailsでWeb開発やってます。JavaScriptもちょっと書きます。k8sの面倒とかも見てます。</span>
              </p>
              <p>
                なおこのブログはまだ構築途中です。<br />
                <span style={{fontWeight: `bold`, color: `white`,}}>
                  依然としてぜんぜんわからない。俺は雰囲気でGraphQLを書いている。
                </span>
              </p>
              <div>
                Gatsbyブログ構築 公開ToDo:
                <ul>
                  <li>カテゴリ機能の実装（すべての記事がいずれかのカテゴリ1つに属すようにする）</li>
                  <ul>
                    <li className={`done`}>記事ページでのカテゴリ表示実装</li>
                    <li>カテゴリごとの記事一覧ページ実装</li>
                    <li>トップページでのカテゴリ一覧リンク実装</li>
                  </ul>
                  <li>タグ機能の実装（記事に任意の数のタグを付与できるようにする）</li>
                  <ul>
                    <li>記事ページでのタグ表示実装</li>
                    <li>タグごとの記事一覧ページ実装</li>
                    <li>トップページでのタグ一覧リンク実装</li>
                  </ul>
                  <li>記事ごとに登録したキーワードがmetaに反映されるようにする（なってたっけ？）</li>
                  <li>記事ごとにキーワードを登録する</li>
                  <li className={`done`}>Font Awesomeを使えるようにする</li>
                  <li>Sassを使えるようにする</li>
                  <li>コメント機能の検討（付けるとしたらDisqusが現実的？）</li>
                  <li>サイドバー実装させるかどうするか検討</li>
                  <li>SNS共有ボタンの実装</li>
                </ul>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/profile.png/" }) {
      childImageSharp {
        fixed(width: 80, height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
