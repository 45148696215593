import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import Logo from "../components/logo"
import Bio from "../components/bio"

class Layout extends React.Component {
  render() {
    const { children } = this.props
    return (
      <div>
        <div
          style={{
            marginLeft:  `auto`,
            marginRight: `auto`,
            paddingLeft:  rhythm(1 / 2),
            paddingRight: rhythm(1 / 2),
            maxWidth: rhythm(30),
          }}
        >
          <header
            style={{
              paddingTop:    rhythm(3 / 4),
              paddingBottom: rhythm(3 / 4),
            }}
          >
            <h1
              style={{
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `none`,
                }}
                to={`/`}
              >
                <Logo />
              </Link>
            </h1>
          </header>

          <div
            style={{
            }}
          >
            <main
              style={{
              }}
            >
              {children}
            </main>

            <aside
              style={{
              }}
            >
            </aside>
          </div>

        </div>

        <div
          style={{
            backgroundColor: `rgba(0, 0, 0, 0.5)`,
          }}
        >
          <div
            style={{
              marginLeft:  `auto`,
              marginRight: `auto`,
              paddingLeft:  rhythm(1 / 2),
              paddingRight: rhythm(1 / 2),
              maxWidth: rhythm(30),
            }}
          >
            <Bio />
          </div>
        </div>

        <footer
          style={{
            textAlign: `center`,
            color: `#999`,
            marginBottom: rhythm(1),
          }}
        >
          © 2018 ha4gu / built with
          {` `}
          <a
            href="https://www.gatsbyjs.org"
            style={{
              color: `#87CEEB`,
            }}
          >
            Gatsby
          </a>
        </footer>
      </div>
    )
  }
}

export default Layout
