import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { rhythm } from "../utils/typography"

function Logo() {
  const logoMaxWidth = `400px`
  return (
    <StaticQuery
      query={logoQuery}
      render={data => {
        return (
          <Img
            id="siteLogo"
            fluid={data.logo.childImageSharp.fluid}
            alt={data.site.siteMetadata.title}
            style={{
              maxWidth: logoMaxWidth,
              marginRight: rhythm(1),
            }}
            imgStyle={{
            }}
          />
        )
      }}
    />
  )
}

const logoQuery = graphql`
  query LogoQuery {
    site {
      siteMetadata {
        title
      }
    }
    logo: file(absolutePath: { regex: "/logo.png/" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 95) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export default Logo
