import { MOBILE_MEDIA_QUERY } from "typography-breakpoint-constants"

const theme = {
  title: "Nerima Damansara Theme",
  baseFontSize: "16px",
  baseLineHeight: 7 / 4,
  headerFontFamily: ["-apple-system", "BlinkMacSystemFont", "Helvetica Neue", "Helvetica", "YuGothic", "Yu Gothic", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"],
  bodyFontFamily: ["-apple-system", "BlinkMacSystemFont", "Helvetica Neue", "Helvetica", "YuGothic", "Yu Gothic", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "Meiryo", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"],
  headerColor: "#2c2c2c",
  bodyColor: "#2c2c2c",
  headerWeight: "normal",
  bodyWeight: "normal",
  boldWeight: "bold",
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    body: {
      backgroundColor: `#333333`,
      backgroundImage: `url(/backgroundPattern.png)`,
    },
    a: {
      color: "#883D12",
      textDecoration: "none",
    },
    "a:hover, a:active": {
      color: "#cc3333",
    },
    blockquote: {
      ...scale(1 / 6),
      background: `#cccccc`,
      fontStyle: `italic`,
      padding: rhythm(1 / 2),
      marginLeft: 0,
      marginRight: 0,
      borderLeft: `${rhythm(1 / 8)} solid #b2791e`,
    },
    "blockquote > :last-child": {
      marginBottom: 0,
    },
    "blockquote cite": {
      ...adjustFontSizeTo(options.baseFontSize),
      color: options.bodyColor,
      fontWeight: options.bodyWeight,
    },
    "blockquote cite:before": {
      content: '"— "',
    },
    ul: {
      listStyle: "disc",
    },
    "ul ul": {
      listStyle: "circle",
      marginBottom: 0,
    },
    "ul ul ul": {
      listStyle: "disc",
    },
    "li, li > p": {
      marginBottom: 0,
    },
    "li > ul": {
      marginTop: 0,
    },
    img: {
      marginBottom: 0,
    },
    [MOBILE_MEDIA_QUERY]: {
      // "ul,ol": {
      //   marginLeft: rhythm(1),
      // },
      // blockquote: {
      //   marginLeft: rhythm(-3 / 4),
      //   marginRight: rhythm(-3 / 4),
      //   paddingLeft: rhythm(11 / 16),
      //   paddingRight: rhythm(3 / 4),
      // },
    },
  }),
}

export default theme
